import $ from 'jquery'

export default {
  state: {
    id: '',
    username: '',
    photo: '',
    token: '',
    is_login: false,
    pulling_info: true // 是否正在拉取信息
  },
  getters: {
  },
  mutations: {
    updateUser (state, user) {
      state.id = user.id
      state.username = user.username
      state.photo = user.photo
      state.is_login = user.is_login
    },
    updateToken (state, token) {
      state.token = token
    },
    logout (state) {
      state.id = ''
      state.username = ''
      state.photo = ''
      state.token = ''
      state.is_login = false
    },
    updatePullingInfo (state, pulling_info) {
      state.pulling_info = pulling_info
    }
  },
  actions: {
    login (context, data) {
      $.ajax({
        url: 'https://kob.syh118.top/api/user/account/token/',
        type: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({
          username: data.username,
          password: data.password
        }),
        success: (res) => {
          if (res.error_message === 'success') {
            localStorage.setItem('jwt_token', res.token)
            context.commit('updateToken', res.token)
            data.success(res)
          } else {
            data.error(res)
          }
        },
        error: (res) => {
          data.error(res)
        }
      })
    },
    getinfo (context, data) {
      $.ajax({
        url: 'https://kob.syh118.top/api/user/account/info/',
        type: 'get',
        headers: {
          Authorization: 'Bearer ' + context.state.token
        },
        success: (res) => {
          if (res.error_message === 'success') {
            context.commit('updateUser', {
              ...res,
              is_login: true
            })
            data.success(res)
          } else {
            data.error(res)
          }
        },
        error: (res) => {
          data.error(res)
        }
      })
    },
    logout (context) {
      localStorage.removeItem('jwt_token')
      context.commit('logout')
    }
  },
  modules: {
  }
}
